<template>
  <div :class="[fade_class,'page']">
    <img :src="product_banner" style="width: 100vw;" />
    <!-- <img :src="product_class" style="margin: 5vw auto 2.6vw;display: block;width: 9.375vw;" /> -->
    <div style="text-align: center;color: #cd652d;font-size: 2vw;margin: 4vw 0 2.6vw;letter-spacing: .2vw;font-weight: 500;">产品分类</div>
    <div style="margin: 0 auto;width: 45vw;text-indent: 2rem;font-size: 1.04vw;color: #666;line-height: 1.8vw;text-align: center;">经过不断的研发创新,公司已形成多元、完善的产品矩阵，目前已形成包子类、便当类、饭团类、寿司类、汤粥类、粉面类等五大鲜食类别,为广大消费者带来了丰富、新鲜、健康、美味的味蕾享受。</div>
    <div style="width: 75vw;margin: 80px auto 0;font-size: 0;line-height: 0;">
      <img class="product_type" :src="product_bento" style="width: 37.2vw;" />
      <img class="product_type" :src="product_rics" style="width: 37.2vw;margin-left: .6vw;" />
      <img class="product_type" :src="product_sushi" style="width: 24.6vw;" />
      <img class="product_type" :src="product_powdered" style="width: 24.6vw;margin-left: .6vw;" />
      <img class="product_type" :src="product_congee" style="width: 24.6vw;margin-left: .6vw;" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'products-',
  props: {
    fade_class: String
  },
  data () {
    return {
      product_banner: require('@/assets/product_banner.png'),
      product_class: require('@/assets/product_class.png'),
      product_bento: require('@/assets/product_bento.png'),
      product_congee: require('@/assets/product_congee.png'),
      product_powdered: require('@/assets/product_powdered.png'),
      product_sushi: require('@/assets/product_sushi.png'),
      product_rics: require('@/assets/product_rics.png'),
    }
  },
  methods: {
    changeProduct(e){
      this.products[this.productIdx].class = ''
      this.products[e.id].class = 'active'
      this.productIdx = e.id
      let _this = this
      this.fade_product = 'fade_out'
      setTimeout(() => {
        _this.productImg = e.img
        _this.product_title = e.title
        _this.product_subtitle = e.subtitle
        _this.product_desc = e.desc
        _this.fade_product = 'fade_in'
      },200)
    }
  }
}
</script>

<style scoped>
  .product_type{
    display: inline-block;
    transition: all ease .1s;
    margin-top: .6vw;
  }
  .product_type:hover{
    transform: scale(1.02);
    box-shadow: 0 0px 5px rgba(0, 0, 0, .1);
  }
</style>
