<template>
  <div style="background-color: #fff;height: 3.646vw;line-height: 3.646vw;text-align: right;box-sizing: border-box;display: inline-block;font-size: 1.04vw;display: block;padding: 0 12.5vw;box-sizing: border-box;margin: 0 auto;position: fixed;top: 0;width: 100vw;z-index: 1;">
    <div style="display: inline-block;float: left;">湖北农发古唐鲜食科技有限公司</div>
    <a :class="[ 'headMenu', isHome ]" href="#/">首页</a>
    <a :class="[ 'headMenu', isProducts ]" href="#/products">产品介绍</a>
    <a :class="[ 'headMenu', isStudents ]" href="#/students">智膳美食</a>
    <a :class="[ 'headMenu', isContactus ]" href="#/contactus">联系我们</a>
  </div>
  <div style="width: 100%;height: 3.646vw;"></div>
  <component :is="currentView" :fade_class="fade_class" />
  <div style="width: 100%;height: 15.625vw;background-color: #2c445c;margin-top: 5.21vw;">
    <div style="width: 75vw;margin: 0 auto;">
      <div v-for="group in menus" :key="group" class="footer_menu">
        <div class="footer_menu_title">{{group.title}}</div>
        <a v-for="menu in group.childs" :key="menu" :href="menu.url"><div class="footer_menu_url">{{menu.title}}</div></a>
      </div>
      <div style="float: right;display: inline-block;box-sizing: border-box;padding: 1.56vw 0;vertical-align: top;">
        <div class="footer_menu_title">联系我们</div>
        <div class="footer_menu_contact"><img class="footer_menu_icon" :src="bottom_landline"/>027-83899089</div>
        <div class="footer_menu_contact" style="line-height: 1.84vw;"><img class="footer_menu_icon" :src="bottom_address" style="width: 1.84vw;height: 1.84vw;padding: 0 .23vw;" />湖北省武汉市东西湖区九通路11号武汉中小企业城89栋</div>
      </div>
    </div>
  </div>
  <!-- <div style="text-align: center;background-color: #1e3348;color: #8e8e8e;height: 2.71vw;line-height: 2.71vw;font-size: 0.94vw;">备案号: 鄂ICP备202100706号-1</div> -->
</template>

<script>
import { ref } from 'vue'
import home from './components/home.vue'
import products from './components/products.vue'
import students from './components/students.vue'
import contactus from './components/contactus.vue'
const routes = {
  '/': home,
  '/products': products,
  '/students': students,
  '/contactus': contactus
}
const currentPath = ref(window.location.hash)
document.title = '湖北农发古唐鲜食科技有限公司'

export default {
  name: 'tyHome',
  components: {
  },
  data () {
    return {
      bottom_address: require('@/assets/bottom_address.png'),
      bottom_landline: require('@/assets/bottom_landline.png'),
      fade_class: 'fade_in',
      menus: [
        {
          title: '首页',
          childs: [
            {title: '公司介绍',url: '#/'},
            {title: '技术团队',url: '#/'},
            {title: '工厂规模',url: '#/'},
            {title: '产品矩阵',url: '#/'},
            {title: '合作品牌',url: '#/'}
          ]
        },{
          title: '产品介绍',
          childs: [
            {title: '饭团类',url: '#/products'},
            {title: '寿司类',url: '#/products'},
            {title: '粉面类',url: '#/products'},
            {title: '汤粥类',url: '#/products'},
            {title: '便当类',url: '#/products'}
          ]
        }
      ]
    }
  },
  computed: {
    currentView() { return routes[currentPath.value.slice(1) || '/'] || home },
    isHome() { return { active: !currentPath.value || currentPath.value == '#/' } },
    isProducts() { return { active: currentPath.value == '#/products' } },
    isStudents() { return { active: currentPath.value == '#/students' } },
    isContactus() { return { active: currentPath.value == '#/contactus' } }
  },
  created() {
    let _this = this
    window.addEventListener('hashchange', () => {
      _this.fade_class = 'fade_out'
      setTimeout(()=>{
        currentPath.value = window.location.hash
        setTimeout(()=>{
          _this.fade_class = 'fade_in'
        },100)
      },100)
    })
  },
  beforeUnmount(){
    let _this = this
    window.removeEventListener('hashchange', () => {
      _this.fade_class = 'fade_out'
      setTimeout(()=>{
        currentPath.value = window.location.hash
        setTimeout(()=>{
          _this.fade_class = 'fade_in'
        },100)
      },100)
    })
  },
}
</script>

<style>
  body{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Microsoft YaHei";
  }
  .page{
    transition: all ease .1s;
    opacity: 0;
  }
  .page.fade_in{
    opacity: 1;
  }
  .page.fade_out{
    opacity: 0;
  }
  .headMenu{
    text-decoration: none;
    color: #333;
    padding: 0 1.56vw;
    transition: all ease .3s;
    line-height: 3.646vw;
    display: inline-block;
    position: relative;
  }
  .headMenu:hover{
    background-color: #8dc2ff;
    color: #fff;
  }
  .headMenu.active{
    color: #cc652d;
  }
  .headMenu::after{
    content: '';
    height: 2px;
    border-radius: 1px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 2.6vw);
    background-color: #cc652d;
    left: 1.3vw;
    opacity: 0;
    transition: all ease .3s;
  }
  .headMenu.active::after{
    opacity: 1;
  }
  .footer_menu{
    display: inline-block;
    height: 15.625vw;
    box-sizing: border-box;
    padding: 1.56vw 12vw 1.56vw 2.19vw;
    position: relative;
    vertical-align: top;
  }
  .footer_menu::before{
    content: '';
    width: 1px;
    height: 11.875vw;
    background-color: #fff;
    position: absolute;
    left: 12px;
    top: 1.875vw;
  }
  .footer_menu::after{
    content: '';
    width: 3px;
    height: 1.04vw;
    background-color: #fff;
    position: absolute;
    left: 11px;
    top: 1.875vw;
  }
  .footer_menu_title{
    font-family: "Microsoft YaHei";
    font-size: 1.146vw;
    color: #fff;
    padding-bottom: 6px;
  }
  .footer_menu_url{
    margin-top: 0.625vw;
    font-family: "Microsoft YaHei";
    font-size: 1.04vw;
    color: #d7d2d2;
    cursor: pointer;
  }
  .footer_menu_url:hover{
    text-decoration: underline;
  }
  .footer_menu_contact{
    font-family: "Microsoft YaHei";
    font-size: 1.146vw;
    color: #fff;
    line-height: 2.29vw;
    margin-top: 0.83vw;
  }
  .footer_menu_icon{
    width: 2.29vw;
    height: 2.29vw;
    display: inline-block;
    vertical-align: top;
  }
</style>
