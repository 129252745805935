<template>
  <div :class="[fade_class,'page']">
    <img :src="require('@/assets/contact_banner.png')" style="width: 100vw;" />
    <img :src="require('@/assets/contact_map.png')" style="width: 75vw;margin: 5vw auto 0;display: block;"/>
    <div style="box-sizing: border-box;padding: 1.6vw;width: 75vw;margin: 0 auto;position: relative;border-bottom: 1px solid #cd652c;">
      <div style="font-size: 1.04vw;color: #333;">地址：</div>
      <div style="font-size: 1.25vw;color: #333;margin-top: 1vw;">湖北省 武汉市 东西湖区 九通路11号 武汉中小企业城 89栋</div>
      <img :src="require('@/assets/contact_address_icon.png')" style="position: absolute;right: 1.6vw;width: 1.875vw;top: 2.4vw;" />
    </div>
    <div style="box-sizing: border-box;padding: 1.6vw;width: 75vw;margin: 0 auto;">
      <div style="font-size: 1.04vw;color: #333;">电话：</div>
      <div style="font-size: 1.25vw;color: #333;margin-top: 1vw;">027-83899089</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactus-',
  props: {
    fade_class: String
  }
}
</script>

<style scoped>
</style>
