<template>
  <div :class="[fade_class,'page']">
    <img :src="homepage_banner" style="width: 100vw;" />
    <div style="text-align: center;font-size: 2.08vw;color: #333;margin-top: 5.2vw;">湖北古唐农发鲜食科技有限公司</div>
    <div v-for="text in texts" :key="text" class="homepage_text">{{text}}</div>
    <swiper class="swiper" :modules="modules" navigation autoplay loop>
      <swiper-slide><img :src="home_team" style="width: 75vw;" /></swiper-slide>
      <swiper-slide><img :src="home_scale" style="width: 75vw;" /></swiper-slide>
      <swiper-slide><img :src="home_product" style="width: 75vw;" /></swiper-slide>
      <swiper-slide><img :src="home_branding" style="width: 75vw;" /></swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import { Navigation, Autoplay } from 'swiper'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css'
  import 'swiper/css/navigation'

export default {
  name: 'home-',
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
      return {
        modules: [Navigation, Autoplay]
      }
    },
  props: {
    fade_class: String
  },
  data () {
    return {
      homepage_banner: require('@/assets/homepage_banner.png'),
      home_team: require('@/assets/home_team.png'),
      home_scale: require('@/assets/home_scale.png'),
      home_product: require('@/assets/home_product.png'),
      home_branding: require('@/assets/home_branding.png'),
      texts: ['湖北农发古唐鲜食科技有限公司(以下简称“公司”)是一家现代化、科技化、集成化的鲜食供应商。','公司成立于2015年2月3日(原名鲜稻家),2019年2月,公司纳入中国最大规模的鲜食供应商-古唐风旗下,斥资1200万元进行了全方位的食品工业升级改造打造了全系列日本进口鲜食制造生产工艺与包装流水线,厂房面积高达8000㎡。','公司发展势头强劲,发展优势突出。严格规范的鲜食生产体系,科学标准的工艺流程,大型现代化鲜食加工设备,专业技术的鲜食管理团队,为公司进入鲜食供应领域第一梯队奠定了坚实的基础。','健康放心、绿色快捷。公司不仅为知名便利店、商超提供定制鲜食服务，还为省市机关部门食堂、金融机构、院校及各类大型活动赛事等提供定制配餐服务。','创食品正道,作善念善行。随着鲜食产业的蓬勃发展,公司业务正突飞猛进,未来公司将以武汉市场为中心,持续深耕华中地区,辐射全国市场,打造国内一流的鲜食品牌。']
    }
  }
}
</script>

<style scoped>
  .home_banner{
    width: 36.46vw;
    height: 20.52vw;
    display: inline-block;
    position: relative;
    vertical-align: top;
  }
  .homepage_text{
    font-size: 1.04vw;
    color: #666;
    text-indent: 2rem;
    line-height: 2vw;
    margin: 2vw auto;
    width: 60vw;
  }
  .swiper{
    width: 75vw;
    margin: 7.3vw auto;
  }
  /deep/.swiper-button-next,/deep/.swiper-button-prev{
    background-color: rgba(0, 0, 0, .3);
    color: #fff;
    padding: 4px 12px;
  }
</style>
