<template>
  <div :class="[fade_class,'page']">
    <img :src="student_meal_banner" style="width: 100vw;" />
    <div style="text-align: center;color: #cd652d;font-size: 2vw;margin: 4vw 0 2.6vw;letter-spacing: .2vw;font-weight: 500;">智膳美食-您的美食专家</div>
    <div style="margin: 0 auto;width: 45vw;text-indent: 2rem;font-size: 1.04vw;color: #666;line-height: 1.8vw;text-align: center;">智膳美食是一款为企业、机构客户（企业、学生餐家长）提供在线查看每日菜单信息的应用平台。平台每周提前更新七天菜品信息，企业员工或学生家长可通过平台提前知晓未来菜谱，并在线留言反馈在企业和校园的就餐服务。我们的宗旨是创食品正道，做善念膳食。</div>
    <div style="width: 75vw;margin: 80px auto 0;font-size: 0;line-height: 0;">
      <img :src="student_meal_img1" style="width: 24vw;" />
      <img :src="student_meal_img2" style="width: 24vw;margin-left: 1.5vw;" />
      <img :src="student_meal_img3" style="width: 24vw;margin-left: 1.5vw;" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'students-',
  props: {
    fade_class: String
  },
  data () {
    return {
      student_meal_banner: require('@/assets/student_meal_banner.png'),
      student_meal_img1: require('@/assets/student_meal_img1.png'),
      student_meal_img2: require('@/assets/student_meal_img2.png'),
      student_meal_img3: require('@/assets/student_meal_img3.png')
    }
  },
  methods: {
    changeProduct(e){
      this.products[this.productIdx].class = ''
      this.products[e.id].class = 'active'
      this.productIdx = e.id
      let _this = this
      this.fade_product = 'fade_out'
      setTimeout(() => {
        _this.productImg = e.img
        _this.product_title = e.title
        _this.product_subtitle = e.subtitle
        _this.product_desc = e.desc
        _this.fade_product = 'fade_in'
      },200)
    }
  }
}
</script>

<style scoped>
</style>
